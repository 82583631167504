@font-face {
    font-family: Avenir;
    src: url('../static/assets/fonts/AvenirLTStd-Black.otf'), url('../static/assets/fonts/AvenirLTStd-Book.otf'), url('../static/assets/fonts/AvenirLTStd-Roman.otf');
}

html {
    box-sizing: border-box;
    font-family: Avenir, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
    font-weight: 900;
    font-size: 10px;
    text-shadow: 0 2px 0 rgba(0, 0, 0, 0.07);
    --currentBackgroundColor: #000000;
    --currentHighlightColor: #ffffff;
}

body {
    color: #ffffff;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    background-color: var(--currentBackgroundColor);
    margin: 0;
    user-select: none;
    overflow: hidden;
}

canvas {
    position: absolute;
    top: 0;
    left: 0;
}

#mouseCoordinates {
    position: absolute;
    color: white;
}

#gameCompleteMenu {
    height: 100vh;
    width: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 50vh;
    align-items: center;
}

#BobRossDoingTheFlossLikeABoss {
    position: absolute;
}

.menu {
    background-color: green;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 70vw;
    height: 50vh;
    border: 3px solid var(--currentHighlightColor);
    color: var(--currentHighlightColor);
    font-size: 2rem;
    padding: 2rem;
}

.hidden {
    display: none !important;
    height: 0 !important;
    width: 0 !important;
    overflow: hidden !important;
}